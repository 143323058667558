<template>
  <viewcard--c title="Convidados" :btnew="btnew">
    <b-row class="mb-1 d-flex justify-content-end">
      <b-col md="5">
        <b-input-group>
          <b-form-input
            placeholder="Pesquise por Nome, Email, Telefone..."
            autocomplete="off"
            v-model="search"
            @keyup.enter="filter"
          />
          <b-input-group-append>
            <b-button variant="info" @click="filter">
              <feather-icon icon="SearchIcon" class="mr-50"/>
              <span>Pesquisar</span>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <view--c :permission="'permission.teacher.view'" :busy="isloading">
      <Table
        :fields="fields"
        :list="!isloading && list[currentePage] ? list[currentePage].itens : []"
        @orderBy="getRecordsOrderBy"
        @rowClick="onClickSelected"
        border="full"
        responsive
      />
      
      <b-pagination
        v-model="currentePage"
        @change="getLoadMore"
        :total-rows="rows"
        :per-page="size"
        v-if="rows > 1"
        first-number
        last-number
        align="center"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </view--c>
  </viewcard--c>
</template>

<script>
import Table from "@/components/Table.vue";
import _guestsService from "@/services/guests-service";
export default {
  components: {
    Table,
  },
  data() {
    return {
      btnew: {
        permission: "permission.guest.create",
        to: "/pedagogic/guest/0",
      },
      isloading: false,
      currentePage: 1,
      search: null,
      size: this.$utils.paginationSize(),
      rows: 1,
      fields: [
        { key: "name", label: "Nome", orderBy: { type: "back" } },
        { key: "email", label: "E-mail", orderBy: { type: "back" } },
        { key: "phone", label: "Telefone", orderBy: { type: "back" } },
      ],
      list: [
        {
          page: 0,
          itens: [],
        },
      ],
      orderByParams: {
        search: '',
        ascOrDes: true,
        orderByKey: ''
      },
    };
  },
  created() {
    this.getRecords(this.currentePage);
  },
  methods: {
    getRecords(_page, isOrder = false) {
      this.isloading = true;
      _guestsService
        .show(_page, this.orderByParams)
        .then((res) => {
          if (res.content) {
            this.list.push({ page: _page, itens: res.content });
            if (res.content.length > 0) {
              if (isOrder) return;
              this.rows += res.content.length;
              this.currentePage = _page;
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.orderList();
          this.isloading = false;
        });
    },
    getLoadMore(_page) {
      if (!this.list.some((s) => s.page === _page)) {
        this.getRecords(_page);
      }
    },
    getRecordsOrderBy(_params) {
      this.orderByParams.ascOrDes = _params.ascOrDes;
      this.orderByParams.orderByKey = _params.orderByKey;
      let oldList = this.list.map((m) => m);
      this.list = [{ page: 0, itens: [] }];
      oldList.forEach((l) => {
        if (l.page < 1) return;
        this.getRecords(l.page, true);
      });
    },
    orderList() {
      this.list.sort((a, b) => {
        if (a.page < b.page) return -1;
        if (a.page > b.page) return 1;
        return 0;
      });
    },
    filter() {
      this.currentePage = 1;
      this.rows = 1;
      this.list = [{ page: 0, itens: [] }];
      this.orderByParams.search = this.search;
      this.getRecords(this.currentePage);
    },
    onClickSelected(record, _) {
      this.$router.push({
        path: `/pedagogic/guest/${record.id}`,
      });
    },
  },
};
</script>